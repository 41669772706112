import axios from "axios";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import CONFIG from "../../config.json";
import React, { useState, useEffect } from 'react';
import { slice } from 'lodash';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from "../services/urlService";
import GetEnvironment  from "../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import  LoadMoreAssetLive from "./LoadMoreAssetsLive";
import  LoadMoreAssetDev from "./LoadMoreAssetsDev";
import LocalStorageService from "../services/localStorageService";

function Posts() {
 
  const [env,  setEnv] = useState('')

  useEffect(() => {
    setEnv(GetEnvironment())
  }, [])

  useEffect(() => {
    LocalStorageService("set", "environment", GetEnvironment());
  }, [])
 

  useEffect(() => {
    LocalStorageService("remove", "productid");
  }, [])
 
 return (
    <div>      
        {env=="live" ? (
         <LoadMoreAssetLive/>
        ) : (
          <LoadMoreAssetDev/>
        )}
      </div>
      )
}
export default Posts


