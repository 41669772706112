import LocalStorageService from "../services/localStorageService";
 export default function LogUserOut(){
//     LocalStorageService("remove", "userType") 
//     LocalStorageService("remove", "token")
//     LocalStorageService("remove", "userEmail")
//     LocalStorageService("remove", "email")
//     LocalStorageService("remove", "mainimage");
//     LocalStorageService("remove", "opt1image");
//     LocalStorageService("remove", "opt2image");
//     LocalStorageService("remove", "opt3image");
//     LocalStorageService("remove", "video");
//     LocalStorageService("remove", "assetNote");
//     LocalStorageService("remove", "assetDescription");
//     LocalStorageService("remove", "assetName");
//     LocalStorageService("remove", "assetPrice");
//     LocalStorageService("remove", "reply");
//     LocalStorageService("remove", "replyerName"); 
//     LocalStorageService("remove", "Id");
//     LocalStorageService("remove", "prodName");
     LocalStorageService("clear");
  }