import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Home from "./clientapp/pages/Home";
import Services from "./clientapp/pages/Services";
import Contact from "./clientapp/pages/messages/SendContactMessage";
import Menue from "./clientapp/pages/Menue";
import References from "./clientapp/pages/References";
import Booking from "./clientapp/pages/Booking";
import Appointments from "./clientapp/pages/Appointments";
import Interets from "./clientapp/pages/Interets";
import Register from "./clientapp/registerlogin/Register_delete";
import RegisterSimple from "./clientapp/registerlogin/RegisterSimple";
import ChangePassword from "./clientapp/registerlogin/ChangePassword";
import Loginscreen from "./clientapp/registerlogin/LoginScreen";
import Login from "./clientapp/registerlogin/Login";
import MessageMyAdmin from "./clientapp/admin/myAdmin/MessageAdminPage";
import MyAdmin from "./clientapp/admin/myAdmin/MyDashBoard";
import ServiceSubscription from "./clientapp/admin/myAdmin/ServiceSubscription";
import Logout from "./clientapp/registerlogin/Logout";
import Authservice2 from "./clientapp/Authentication/AuthService";
import Partnerservice from "./clientapp/partners/Partnerservice";
import UsefullLinks from "./clientapp/admin/myAdmin/UsefullLinks";
import ThankYouUploaded from "./clientapp/uploadMedia/ThankyouAfterUploaded";
import UploadAssets from "./clientapp/uploadMedia/UploadTextInputs";
import AssetDetailsDev from "./clientapp/loadPages/AssetDetailPageDev";
import AssetDetailsLive from "./clientapp/loadPages/AssetDetailPageLive";
import UploadVideo from "./clientapp/uploadMedia/UploadVideo";
import UploadPictures from "./clientapp/uploadMedia/UploadPictures";
import ThankyouAfterRegistration from "./clientapp/registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./clientapp/registerlogin/ThankyouPasswordChanged";
import BuyerService from "./clientapp/buyers/BuyerServices";
import BuyerMessages from "./clientapp/buyers/MyMessagePage";
import BuyerAppointments from "./clientapp/buyers/MyAppointments";
import BuyerProperties from "./clientapp/buyers/MyProperty";
import AppointmentMessages from "./clientapp/admin/appointments/AppointmentMessagePage";
import BookingMessages from "./clientapp/admin/bookings/BookingMessagesPage";
import ExpressedInterestMessages from "./clientapp/admin/interestexpressed/ExpressedInterestMessagesPage";
import GenerateInvoice from "./clientapp/admin/invoice/GenerateInvoice";
import InvoicePage from "./clientapp/admin/invoice/InvoicePage";
import GenerateFoodMenue from "./clientapp/admin/foodMenue/GenerateFoodMenue";
import FoodMenueASAChefPage from "./clientapp/admin/foodMenue/FoodMenuePageAsAChef";
import FoodMenuePage from "./clientapp/admin/foodMenue/FoodMenuePage";
import GenerateOrder from "./clientapp/admin/orders/GenerateOrder";
import OrdersPage from "./clientapp/admin/orders/OrdersPage";
import MakePayment from "./clientapp/payments/MakePayment";
import SendPaymentConfirmation from "./clientapp/payments/SendPaymentConfirmation";
import InvoiceByNumberPage from "./clientapp/admin/invoice/InvoiceByNumberPage";
class Main extends Component {
  render() {
    return (
      <div>
        <HashRouter>
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} /> 
          <Route path="/appointmentmessages" component={AppointmentMessages} /> 
          <Route path="/bookingmessages" component={BookingMessages} /> 
          <Route path="/interestexpressed" component={ExpressedInterestMessages} />
          <Route path="/buyerservices" component={BuyerService} /> 
          <Route path="/buyerappointments" component={BuyerAppointments} /> 
          <Route path="/   " component={BuyerProperties} /> 
          <Route path="/buyermessages" component={BuyerMessages} /> 
          <Route path="/uploadedthanks" component={ThankYouUploaded} />
          <Route path="/assetdetailsdev" component={AssetDetailsDev} />
          <Route path="/assetdetailslive" component={AssetDetailsLive} />          
          <Route path="/services" component={Services} />
          <Route path="/menue" component={Menue} />
          <Route path="/reference" component={References} />
          <Route path="/booking" component={Booking} />
          <Route path="/appointments" component={Appointments} />
          <Route path="/interets" component={Interets} />          
          <Route path="/register" component={Register} />
          <Route path="/registersimple" component={RegisterSimple} />
          <Route path="/changepassword" component={ChangePassword} />
          <Route path="/thankyouregistered" component={ThankyouAfterRegistration} /> 
          <Route path="/thankyoupasswordchanged" component={ThankyouPasswordChanged} />           
          <Route path="/adminpage" component={MyAdmin} />
          <Route path="/servicesubscription" component={ServiceSubscription} />
          <Route path="/loginscreen" component={Loginscreen} />
          <Route path="/login" component={Login} />
          <Route path="/contact" component={Contact} />
          <Route path="/messageadmin" component={MessageMyAdmin} />
          <Route path="/usefulllinks" component={UsefullLinks} />
          <Route path="/partnerservice" component={Partnerservice} />
          <Route path="/logout" component={Logout} />         
          <Route path="/authservice2" component={Authservice2} />         
          <Route path="/uploadassets" component={UploadAssets} />
          <Route path="/uploadvideos" component={UploadVideo} />
          <Route path="/uploadpictures" component={UploadPictures} /> 
          <Route path="/generateinvoice" component={GenerateInvoice} /> 
          <Route path="/invoicepage" component={InvoicePage} />
          <Route path="/invoicebynumberpage" component={InvoiceByNumberPage} />          
          <Route path="/generatefoodmenue" component={GenerateFoodMenue} /> 
          <Route path="/foodmenuepage" component={FoodMenuePage} />
          <Route path="/foodmenueasachefpage" component={FoodMenueASAChefPage} />
          <Route path="/generateorder" component={GenerateOrder} /> 
          <Route path="/orderspage" component={OrdersPage} /> 
          <Route path="/makepayment" component={MakePayment} />
          <Route path="/sendpaymentconfirmation" component={SendPaymentConfirmation} />          
          <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(Main);
